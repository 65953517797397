import React from "react";
import { AppBtn } from "../../ui/buttons";
import Modal, { ModalBody, ModalFooter } from "../../ui/modal";

interface Props {
  storeName: string;
  show: boolean;
  toggle: (state: boolean) => void;
}

const ExplainerModal: React.FC<Props> = ({ storeName, show, toggle }) => {
  const steps = [
    "Choose your items by adding them to cart",
    `Open cart, click "Proceed", fill in your delivery info, then click "Send Order"`,
    `You'll be redirected to our whatsapp confirm order & pay directly`,
  ];

  return (
    <Modal {...{ show, toggle }} title={`How to buy`} closeable={false} bgClose={false} size="midi">
      <ModalBody>
        <div className="flex items-center flex-col mt-3">
          <figure className="h-16 w-16 sm:h-20 sm:w-20 rounded-full flex items-center justify-center bg-primary-500">
            {/* prettier-ignore */}
            <svg className="w-1/2 text-white" viewBox="0 0 24 24" fill="none">
              <path d="M19.24 5.57859H18.84L15.46 2.19859C15.19 1.92859 14.75 1.92859 14.47 2.19859C14.2 2.46859 14.2 2.90859 14.47 3.18859L16.86 5.57859H7.14L9.53 3.18859C9.8 2.91859 9.8 2.47859 9.53 2.19859C9.26 1.92859 8.82 1.92859 8.54 2.19859L5.17 5.57859H4.77C3.87 5.57859 2 5.57859 2 8.13859C2 9.10859 2.2 9.74859 2.62 10.1686C2.86 10.4186 3.15 10.5486 3.46 10.6186C3.75 10.6886 4.06 10.6986 4.36 10.6986H19.64C19.95 10.6986 20.24 10.6786 20.52 10.6186C21.36 10.4186 22 9.81859 22 8.13859C22 5.57859 20.13 5.57859 19.24 5.57859Z" fill="currentColor"/>
              <path d="M19.0897 12H4.90971C4.28971 12 3.81971 12.55 3.91971 13.16L4.75971 18.3C5.03971 20.02 5.78971 22 9.11971 22H14.7297C18.0997 22 18.6997 20.31 19.0597 18.42L20.0697 13.19C20.1897 12.57 19.7197 12 19.0897 12ZM14.8797 16.05L11.6297 19.05C11.4897 19.18 11.3097 19.25 11.1197 19.25C10.9297 19.25 10.7397 19.18 10.5897 19.03L9.08971 17.53C8.79971 17.24 8.79971 16.76 9.08971 16.47C9.38971 16.18 9.85971 16.18 10.1597 16.47L11.1497 17.46L13.8697 14.95C14.1697 14.67 14.6497 14.69 14.9297 14.99C15.2097 15.3 15.1897 15.77 14.8797 16.05Z" fill="currentColor"/>
            </svg>
          </figure>
          <h2 className="text-black text-2lg sm:text-2xl font-semibold mt-4 text-center leading-tight">
            How to buy <br /> from our store
          </h2>
        </div>
        <ul className="mt-4  sm:mt-6 bg-grey-loader space-y-3.5 max-w-md mx-auto p-4.5 sm:p-5 rounded-15">
          {steps.map((step, index) => (
            <li className="flex items-start" key={index}>
              <figure className="h-5 w-5 bg-success flex items-center justify-center text-white rounded-full flex-shrink-0 mr-2">
                <svg width={10} viewBox="0 0 11 8" fill="none">
                  <path
                    d="M1 3.83L3.83 6.66L9.5 1"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </figure>
              <span className="inline-block text-dark text-sm sm:text-1sm max-w-sm">{step}</span>
            </li>
          ))}
        </ul>
      </ModalBody>
      <ModalFooter>
        <AppBtn onClick={() => toggle(false)} isBlock size="lg">
          Alright, got it
        </AppBtn>
      </ModalFooter>
    </Modal>
  );
};

export default ExplainerModal;

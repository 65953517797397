import { ProductItemInterface } from "@/assets/interfaces";
import { useEffect, useMemo } from "react";

const useStoreItemsPagination = (data: {
  currentPage: number;
  pagData: { next_page: number | null; prev_page: number | null; total_pages: number | null };
  isLoading: boolean;
  goNext: () => void;
}) => {
  const { currentPage, pagData, goNext, isLoading } = data;

  useEffect(() => {
    const handleInfiniteScroll = (e) => {
      if ((window.innerHeight + window.scrollY) / document.body.offsetHeight >= 0.9) {
        if (currentPage < pagData.total_pages && pagData.next_page && !isLoading) {
          goNext();
        }
      }
    };

    window.addEventListener("scroll", handleInfiniteScroll);

    return () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
    };
  }, [pagData, currentPage]);
};

export default useStoreItemsPagination;

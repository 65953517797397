import React from "react";
import { reloadPage } from "../../assets/js/utils/functions";
import { getStoreFrontSEOTags } from "../../assets/js/utils/utils";
import SEOTags from "../seo-tags";
import { AppBtn } from "../ui/buttons";
import ErrorScreen from "../ui/error-screen";

interface Props {
  notFound: boolean;
  error: boolean;
  store?: any;
  slug: string;
}

const StoreFrontErrors: React.FC<Props> = ({ notFound, error, store, slug }) => {
  if (notFound) {
    return (
      <>
        <SEOTags tags={getStoreFrontSEOTags(slug, store)} />
        <ErrorScreen title="We couldn't find that store" message="Check the store url and try again or:">
          <AppBtn href="/" className="mt-5" size="md">
            Go back home
          </AppBtn>
        </ErrorScreen>
      </>
    );
  }

  if (error || !store) {
    return (
      <ErrorScreen title="Something went wrong!" message="We couldn't load this store, you can try refreshing the page">
        <AppBtn onClick={reloadPage} className="mt-5" size="md">
          Reload page
        </AppBtn>
      </ErrorScreen>
    );
  }

  return null;
};

export default StoreFrontErrors;

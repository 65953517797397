import classNames from "classnames";
import React from "react";

interface DiscountBadgeProps {
  percentage: number;
  className?: string;
}

const DiscountBadge: React.FC<DiscountBadgeProps> = ({ percentage, className }) => {
  return (
    <div
      className={classNames(
        "bg-discount-badge rounded-lg px-1.75 py-1 sm:px-2 sm:py-1.25 text-xxxs sm:text-xxs text-white font-semibold flex",
        className
      )}
    >
      {percentage}% OFF
    </div>
  );
};

export { DiscountBadge };

import { useRouter } from "next/dist/client/router";
import React, { useEffect, useRef } from "react";
import useSearchParams from "../hooks/useSearchParams";
import useTabs from "../hooks/useTabs";
import { createBrowserHistory } from "history";
import { Category } from "../../assets/interfaces";
import classNames from "classnames";

interface Props {
  categories: Category[];
  itemsLoading: boolean;
  resetPage: VoidFunction;
}

const StoreFrontTabs: React.FC<Props> = ({ categories, itemsLoading, resetPage }) => {
  const { category } = useSearchParams(["category"]);
  categories = [{ name: "All", emoji: "" }, ...categories];
  const { tabs, isActive, active, switchTab } = useTabs(
    categories.map((c) => c.name),
    0
  );
  const isFirstLoad = useRef(true);

  useEffect(() => {
    const history = createBrowserHistory();
    const searchParams = new URLSearchParams(window.location.search);
    if (active > 0) {
      searchParams.set("category", categories[active]?.id);

      history.push({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    } else {
      if (!isFirstLoad.current) {
        searchParams.delete("category");

        history.push({
          pathname: location.pathname,
          search: Array.from(searchParams.keys()).length > 0 ? `?${searchParams.toString()}` : undefined,
        });
      }
    }

    if (!isFirstLoad.current) {
      resetPage();
    }

    isFirstLoad.current = false;
  }, [active]);

  useEffect(() => {
    //update selected tab when page loads wit a category query
    const categoryIndex = categories.findIndex((c) => c.id === category);

    if (categoryIndex !== -1 && categoryIndex !== active) {
      switchTab(categoryIndex);
    }
  }, [category]);

  if (categories.length <= 1) {
    return null;
  }

  if (itemsLoading) {
    return (
      <div className="flex items-center w-full overflow-x-auto space-x-4 sm:space-x-5">
        {tabs.map((t, index) => (
          <div className="h-8 w-20 rounded-3xl bg-grey-loader animate-pulse flex-shrink-0" key={index}></div>
        ))}
      </div>
    );
  }
  return (
    <div className="flex items-center w-full overflow-x-auto space-x-4 sm:space-x-5">
      {tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => switchTab(index)}
          className={classNames(`no-outline inline-flex items-center border rounded-full flex-shrink-0`, {
            "bg-accent-yellow-500 bg-opacity-10 border-accent-yellow-500 border-opacity-75": index === active,
            "border-grey-border bg-grey-fields-200 border-opacity-50": index !== active,
            "px-1 pl-1.25 pr-2.5 py-1 md:py-1.25": categories[index].emoji,
            "px-4 sm:px-5 py-1.5 md:py-1.75": !categories[index].emoji,
          })}
        >
          {categories[index].emoji && (
            <span className="shadow-sm rounded-full w-6 h-6 p-0.5 text-1xs sm:text-sm block bg-white mr-1.25">
              {categories[index].emoji}
            </span>
          )}
          <span className="inline-block text-dark font-medium text-1xs sm:text-sm">{tab}</span>
        </button>
      ))}
    </div>
  );
};

export default StoreFrontTabs;

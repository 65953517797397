import React, { useState } from "react";
import { StoreFrontTabs } from ".";
import { CURRENCIES, Category, StoreInterface } from "../../assets/interfaces";
import AppSearchBar from "./search-bar";
import ImageWithPlaceholder from "../ui/image-with-placeholder";
import StoreFromSearchBar from "./search-bar";
import Portal from "../portal";
import StoreFrontSearch from "./search";
import CurrencySwitcher from "../ui/currency-switcher";
import CartContext from "../../contexts/cart-context";

interface Props {
  isTabsShowing: boolean;
  itemsLoading: boolean;
  store: StoreInterface;
  resetPage: () => void;
}

const StoreFrontHeader: React.FC<Props> = ({ isTabsShowing, itemsLoading, store, resetPage }) => {
  const { categories, logo, hero_image: heroImage, name: storeName, has_paid_subscription } = store;
  const [fullViewOnMobile, setFullViewOnMobile] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const { currencies, changeCurrency } = CartContext.useContainer();

  return (
    <>
      <header
        className="w-full store-front-hero px-5 sm:px-9 md:px-12.5 py-7.5" //px-4 sm:px-12 md:px-16 lg:px-20 xl:px-25 py-5 sm:py-7.5
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), ${
            heroImage ? `url(${heroImage})` : `url('/images/default-store-hero.jpeg')`
          }`,
        }}
      >
        <div className="w-full flex items-center justify-between mx-auto md:max-w-[1000px] lg:max-w-[1100px]">
          <div className={`p-1 ${fullViewOnMobile ? "hidden md:block" : ""}`}>
            {/* <figure className="h-5.5 sm:h-6">
              <img src="/images/logos/new-white.svg" alt="" className="h-full" />
            </figure> */}
            {has_paid_subscription ? (
              // <img src={logo} alt="" className="h-full w-full object-cover" />
              <ImageWithPlaceholder
                image={logo}
                text={storeName}
                className="h-10 w-10 md:h-12 md:w-12 text-xl sm:text-2lg font-bold"
              />
            ) : (
              <img src="/images/logos/new-white.svg" alt="" className="h-5.5 sm:h-6" />
            )}
          </div>

          <div className="flex items-center space-x-2.5">
            {currencies.options.length > 1 && (
              <CurrencySwitcher
                selected={currencies.active}
                currencies={currencies.options}
                setCurrency={changeCurrency}
              />
            )}
            <div className="bg-white rounded-[8px] overflow-hidden">
              <button
                className={`items-center justify-center h-8 w-8 sm:h-9 sm:w-9 bg-white text-dark transition-all ease-in-out hover:bg-primary-500 hover:bg-opacity-70 hover:text-white duration-100 hover:text- flex itesms-center `}
                onClick={() => setShowSearch(true)}
              >
                {/* prettier-ignore */}
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path d="M6.875 11.875C9.63642 11.875 11.875 9.63642 11.875 6.875C11.875 4.11358 9.63642 1.875 6.875 1.875C4.11358 1.875 1.875 4.11358 1.875 6.875C1.875 9.63642 4.11358 11.875 6.875 11.875Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.125 13.125L10.4062 10.4062" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              </button>
            </div>
          </div>

          {/* <div className="border border-primary-100 bg-primary-50 rounded-lg h-10 w-10 sm:w-[250px]"></div> */}
          {/* <AppSearchBar {...{ fullViewOnMobile, setFullViewOnMobile, searchQuery, setSearchQuery }} /> */}
        </div>
      </header>

      {/* hidden header */}
      <div
        className={`fixed top-0 left-0 w-full bg-white px-5 sm:px-9 md:px-12.5 py-2.5 sm:py-3.5 border-b border-grey-border border-opacity-70 transform transition-all duration-150 z-[990] ${
          isTabsShowing ? "-translate-y-full opacity-0" : "-translate-y-0 opacity-100"
        }`}
      >
        <div className="w-full flex items-center justify-between  mx-auto md:max-w-[1000px] lg:max-w-[1100px]">
          <div className={`flex items-center ${fullViewOnMobile ? "hidden md:flex" : ""}`}>
            <ImageWithPlaceholder
              image={logo}
              text={storeName}
              className="hidden lg:inline-flex md:h-12 md:w-12 text-xl sm:text-2lg font-bold"
            />
            <div className="max-w-[75vw] sm:max-w-[70vw] md:max-w-[50vw] lg:max-w-[45vw] overflow-hidden lg:ml-8">
              <StoreFrontTabs categories={categories} itemsLoading={itemsLoading} resetPage={resetPage} />
            </div>
          </div>
          <div className="pl-1.5 flex justify-end w-full">
            {/* <AppSearchBar {...{ fullViewOnMobile, setFullViewOnMobile, searchQuery, setSearchQuery }} /> */}
            <button
              className={`items-center justify-center h-8 w-8 sm:h-9 sm:w-9 rounded-[8px] bg-grey-fields-100 text-dark transition-all ease-in-out hover:bg-primary-300 hover:text-white duration-100 hover:text- flex itesms-center border border-grey-divider `}
              onClick={() => setShowSearch(true)}
            >
              {/* prettier-ignore */}
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
              <path d="M6.875 11.875C9.63642 11.875 11.875 9.63642 11.875 6.875C11.875 4.11358 9.63642 1.875 6.875 1.875C4.11358 1.875 1.875 4.11358 1.875 6.875C1.875 9.63642 4.11358 11.875 6.875 11.875Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M13.125 13.125L10.4062 10.4062" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            </button>
          </div>
        </div>
      </div>

      <Portal>
        <StoreFrontSearch show={showSearch} toggle={setShowSearch} resetPage={resetPage} />
      </Portal>
    </>
  );
};

export default StoreFrontHeader;

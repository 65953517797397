import React from "react";
import classNames from "classnames";

interface IProps {
  isInCart: boolean;
  className?: string;
  toggle: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const AddToCartButton: React.FC<IProps> = (props) => {
  const { isInCart, className, toggle } = props;

  return (
    <button
      className={classNames(
        `h-8 w-8 sm:h-8.75 sm:w-8.75 rounded-full flex items-center justify-center shadow-accent flex-shrink-0`,
        {
          "text-white bg-success": isInCart,
          "bg-grey-fields-100 text-add-to-cart": !isInCart,
        },
        className
      )}
      onClick={toggle}
    >
      {isInCart ? (
        <svg viewBox="0 0 11 8" fill="none" className="transform translate-x-[2%] h-[9px] md:h-[11px]">
          <path
            d="M10 1L3.8125 7L1 4.27273"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg width="18" viewBox="0 0 14 14" fill="none">
          <path
            d="M7 2.91675V11.0834"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.91675 7H11.0834"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </button>
  );
};

export default AddToCartButton;

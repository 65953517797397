import React, { useEffect, useState } from "react";

interface IProps {
  toggleExplainer: VoidFunction;
}

const InvitationBanner: React.FC<IProps> = ({ toggleExplainer }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasClosedBanner = localStorage.getItem("has-closed-explainer-banner") !== null;

    if (!hasClosedBanner) {
      setTimeout(() => {
        setShowBanner(true);
      }, 8000);
    }
  }, []);

  const hideBanner = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowBanner(false);
    localStorage.setItem("has-closed-explainer-banner", "true");
  };

  const handleBannerClick = () => {
    toggleExplainer();
    setShowBanner(false);
    localStorage.setItem("has-closed-explainer-banner", "true");
  };

  return (
    <div
      className={`fixed bottom-0 left-1/2 -translate-x-1/2 p-3 sm:p-6.25 lg:p-7.5 transform transition-all duration-500 ease-in-out w-full max-w-[450px] sm:min-w-[450px] z-[950] cursor-pointer ${
        showBanner ? "translate-y-0 opacity-100" : "translate-y-full opacity-0 pointer-events-none"
      }`}
      onClick={handleBannerClick}
    >
      <div className="bg-cart-badge rounded-15 shadow-lg px-3.75 py-3 relative flex items-start justify-between">
        <div className="flex items-start text-sm flex-1">
          <figure className="h-12 w-12 bg-white bg-opacity-10 flex items-center justify-center rounded-full mr-3 flex-shrink-0">
            {/* prettier-ignore */}
            <svg className="w-1/2 text-white" viewBox="0 0 24 24" fill="none">
              <path d="M19.24 5.57859H18.84L15.46 2.19859C15.19 1.92859 14.75 1.92859 14.47 2.19859C14.2 2.46859 14.2 2.90859 14.47 3.18859L16.86 5.57859H7.14L9.53 3.18859C9.8 2.91859 9.8 2.47859 9.53 2.19859C9.26 1.92859 8.82 1.92859 8.54 2.19859L5.17 5.57859H4.77C3.87 5.57859 2 5.57859 2 8.13859C2 9.10859 2.2 9.74859 2.62 10.1686C2.86 10.4186 3.15 10.5486 3.46 10.6186C3.75 10.6886 4.06 10.6986 4.36 10.6986H19.64C19.95 10.6986 20.24 10.6786 20.52 10.6186C21.36 10.4186 22 9.81859 22 8.13859C22 5.57859 20.13 5.57859 19.24 5.57859Z" fill="currentColor"/>
              <path d="M19.0897 12H4.90971C4.28971 12 3.81971 12.55 3.91971 13.16L4.75971 18.3C5.03971 20.02 5.78971 22 9.11971 22H14.7297C18.0997 22 18.6997 20.31 19.0597 18.42L20.0697 13.19C20.1897 12.57 19.7197 12 19.0897 12ZM14.8797 16.05L11.6297 19.05C11.4897 19.18 11.3097 19.25 11.1197 19.25C10.9297 19.25 10.7397 19.18 10.5897 19.03L9.08971 17.53C8.79971 17.24 8.79971 16.76 9.08971 16.47C9.38971 16.18 9.85971 16.18 10.1597 16.47L11.1497 17.46L13.8697 14.95C14.1697 14.67 14.6497 14.69 14.9297 14.99C15.2097 15.3 15.1897 15.77 14.8797 16.05Z" fill="currentColor"/>
            </svg>
          </figure>
          <p className="text-1xs text-white pt-0.5">
            After selecting items, you can choose to complete your order with us on Whatsapp. <b>Learn More</b>
          </p>
        </div>
        <button
          type="button"
          className={`no-outline h-6 w-6 rounded-full bg-white bg-opacity-20 transition-all text-white hover:bg-opacity-10 flex items-center justify-center ml-3 flex-shrink-0`}
          onClick={hideBanner}
        >
          <svg width="38%" viewBox="0 0 19.528 19.529">
            <g transform="translate(-314.611 -73.746)">
              <line
                y2="25.617"
                transform="translate(333.432 74.454) rotate(45)"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              />
              <line
                y2="25.617"
                transform="translate(315.318 74.454) rotate(-45)"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default InvitationBanner;
